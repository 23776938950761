body {
  --blue: #242957;
  --blueDark: #161c31;
  --green: #57a528;
  --grayLight: #ebebec;
  --grayText: #bdbdbd;
}

.text-gray {
  color: var(--grayText);
}
.text-green {
  color: var(--green);
}
.text-red {
  color: red;
}

.text-black {
  color: black;
}