@import "./colors.css";


.li-nostyle {
  list-style-image: none;
  list-style-type: none;
}

/**/

#menuBurger i {
  font-size: 25px;
}

#mainHeader {
  height: 300px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

#mainHeader h1 {
  color: white;
  font-size: 50px;
  position: relative;
  z-index: 100;
}

#mainHeader img {
  bottom: 0;
  height: auto;
  position: absolute;
  width: 100%;
}

#mainContent {
  background: var(--grayLight);
}

/* navTop */

#navTop {
  background: var(--blue);
  box-shadow: none;
  width: 100%;
  z-index: 100;
}

.btn-phode-green {
  background-color: var(--green);
}

#menuBurger.btn-phode {
  background-color: var(--blue);
  box-shadow: none;
  border-radius: 100%;
  padding: 10px 15px;
  width: 55px;
}

.add.btn-green {
  background-color: var(--green) !important;
  border-radius: 100%;
  padding: 10px 15px;
}

/* Main */

main {
  background: var(--grayLight);
}

.page-list #mainHeader {
  height: 125px;
}
.page-list h1 {
  justify-content: left;
  margin-top: 30px;
}

.row img {
  max-width: 100%;
}

