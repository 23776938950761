#mainContent h2 {
  background: var(--blueDark);
  color: white;
  font-size: 30px;
  padding: 25px 30px;
}

#dashboardElements {
  position: relative;
  top: -86px;
}

#cardBottom {
  border-top: 1px solid var(--grayLight);
  height: 50px;
}

.addWrapper {
  bottom: 25px;
  position: absolute;
  right: 20px;
  z-index: 100;
}

li > .row {
  width: 100%;
}

.elementDate,
.elementAuthor {
  color: var(--grayText);
}

.elementImg img {
  object-fit: cover;
  height: 80px;
  width: 100%;
}

.elementAvatar img {
  border-radius: 100%;
  object-fit: cover;
  height: 64px;
  width: 64px;
}

.dashboardElement {
  height: 520px
}

/* Medias */
#medias li,
#news li {
  border-bottom: 1px solid var(--grayLight);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#medias li:last-child,
#news li:last-child {
  border: 0;
  margin: 0;
  padding-bottom: 0;
}

#medias .card-body,
#news .card-body {
  padding-left: 0;
  padding-right: 0;
}

/* Users */

#users li{
  margin-bottom: 20px;
}

#dashboard h1 {
  justify-content: center;
  margin-top: 60px;
}