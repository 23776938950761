@import "./main/colors.css";

#nav {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  height: 100%;
  margin-left: 5px;
  position: fixed;
  z-index: 200;
}
#nav nav {
  background: var(--blueDark);
  height: 100%;
  position: relative;
}

#nav .btn-phode {
  background: var(--blueDark);
  box-shadow: none;
  font-size: 15px;
  margin: 0;
  text-align: left;
  width: 100%;
}
#nav .btn-phode:hover {
  background: var(--green);
}
#nav .btn-phode:after {
  position: absolute;
  right: 30px;
  top: 20px;
}

#logo {
  background: var(--blue);
  padding: 20px;
  text-align: center;
}
#logo img {
  width: 70%;
}