@import "./main/colors.css";

footer {
  color: white;
  position: relative;
  z-index: 300;
}
#footer1 {
  background: var(--blue);
  padding: 25px 0;
}
#footer1 img {
  width: 75%;
}
footer {
  line-height: 30px;
}

#footer1 li {
  line-height: 1;
  list-style-type: none;
  list-style-image: none;
  margin-bottom: 10px;
}
footer a {
  color: white;
}

footer h3 {
  font-size: 18px;
  margin-bottom: 20px;
}
#footer-social {
  font-size: 35px;
}

#footer2 {
  background: var(--blueDark);
  padding: 10px 0;
}